// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../api";
import { usePagination } from "../../hooks";
import { Box, Typography, Grid, Paper, Button } from "@material-ui/core";
import Layout from "../Layout";
import { generateGridData } from "./GenerateGridData";
import { DataGrid } from "../../partials/table/DataGrid";
import AddLicenseModal from "../../partials/modal/AddLicenseModal";
import { useAppStore } from "../../AppStore";
import { useSnackbar } from "notistack";
import DropDown from "../../partials/DropDown";

export default function LicensesList() {
  const [VALUE] = useAppStore();
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setShowLoader] = useState(true);
  const [openAddLicenseModal, setOpenAddLicenseModal] = useState(false);
  const [sortActivationDate, setSortActivationDate] = useState("activated_at");
  const [filters, setFilters] = useState({});
  const [company, setCompany] = useState([]);
  const [gridData, setGridData] = useState({ gridColumns: [], gridRows: [] });
  const {
    pagination,
    paginationSizeHandler,
    paginationPageHandler,
    paginationAmountHandler,
  } = usePagination();

  const userRole = VALUE.currentUser.role;

  const handlerLicenseModal = () => {
    setOpenAddLicenseModal((prev) => !prev);
  };

  const handlerSort = (defaultValue, descValue) => {
    setShowLoader(true);
    setSortActivationDate((prev) =>
      prev === defaultValue ? descValue : defaultValue
    );
  };

  // eslint-disable-next-line
  const getCompanyList = useMemo(() => {
    api
      .post("/public/v1/licenses/company/names/", {
        orderings: ["company_name"],
        pagination: {
          pgoffset: 0,
          pgsize: -1,
        },
      })
      .then(({ data }) => setCompany(data.items));
  }, []);

  const getLicensesList = useCallback(
    (page, rows, sort, filters = {}) => {
      setShowLoader(true);
      api
        .post("/public/v1/licenses/", {
          orderings: [sort],
          pagination: {
            pgoffset: page,
            pgsize: rows,
          },
          filters,
        })
        .then(({ data }) => {
          if (!data) return;
          const { items, amount } = data;
          const dataForGrid = generateGridData(items);
          setGridData(dataForGrid);
          paginationAmountHandler(amount);
          setShowLoader(false);
        });
    },
    [paginationAmountHandler]
  );

  const handlerEnableChange = (license_id, is_enabled) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        is_enabled: !is_enabled,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const handlerEditContractId = (license_id, value) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        contract_id: value.length ? value : null,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("Success", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const editDays = (license_id, days) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        days: days,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("The license has been successfully edited", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const editChannels = (license_id, cameras) => {
    setShowLoader(true);
    api
      .patch(`/public/v1/licenses/${license_id}`, {
        cameras_count: cameras,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("The license has been successfully edited", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const extendActivatedExpired = (license_id, days) => {
    setShowLoader(true);
    api
      .post(`public/v1/licenses/${license_id}/extend/`, {
        days: days,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("License successfully extended", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  const addChannelsActivatedExpired = (license_id, cameras_count) => {
    setShowLoader(true);
    api
      .post(`public/v1/licenses/${license_id}/extend/`, {
        cameras_count: cameras_count,
      })
      .then(() => {
        getLicensesList(
          pagination.offset,
          pagination.size,
          sortActivationDate,
          filters
        );
        enqueueSnackbar("Channels successfully added", {
          variant: "success",
          autoHideDuration: 3000,
        });
      });
  };

  useEffect(() => {
    setShowLoader(true);
    if (filters.status_in && filters.status_in.length === 0) {
      delete filters.status_in;
    }
    if (filters.days_left_in && filters.days_left_in.length === 0) {
      delete filters.days_left_in;
    }
    if (filters.company_name_in && filters.company_name_in.length === 0) {
      delete filters.company_name_in;
    }
    if (!Object.keys(filters).length) {
      getLicensesList(
        pagination.offset,
        pagination.size,
        sortActivationDate,
        filters
      );
    }
    // eslint-disable-next-line
  }, [pagination.offset, pagination.size, sortActivationDate, filters]);

  useEffect(() => {
    if (!!Object.keys(filters).length) {
      paginationSizeHandler(0, pagination.size);
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (
      Object.keys(filters).length > 0 &&
      filters.company_name_in?.length === 1
    ) {
      getLicensesList(0, pagination.size, sortActivationDate, filters);
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      getLicensesList(
        pagination.offset,
        pagination.size,
        sortActivationDate,
        filters
      );
    }
    // eslint-disable-next-line
  }, [pagination.offset, pagination.size, sortActivationDate, filters]);

  return (
    <Layout title="Keys">
      <Box p={2} width={1}>
        <Grid item xs={12}>
          <Paper>
            <Box p={3}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Typography align="left" variant="h3">
                  Subscription Keys
                </Typography>
                <Button onClick={handlerLicenseModal} color="primary">
                  Generate New Key
                </Button>
              </Box>
              <Box mb={2}>
                <DropDown
                  filters={filters}
                  setFilters={setFilters}
                  companyList={company}
                />
              </Box>
              <DataGrid
                isLoading={showLoader}
                pagination={pagination}
                onSizeChange={paginationSizeHandler}
                onPageChange={paginationPageHandler}
                gridColumns={gridData.gridColumns.filter((item) =>
                  userRole === "admin" ? item : item.name !== "Account Manager"
                )}
                gridRows={gridData.gridRows}
                handlerEnableChange={handlerEnableChange}
                data={gridData.gridRows.length > 0}
                handlerSort={handlerSort}
                handlerEditContractId={handlerEditContractId}
                editDays={editDays}
                editChannels={editChannels}
                extendActivatedExpired={extendActivatedExpired}
                addChannelsActivatedExpired={addChannelsActivatedExpired}
                role={userRole}
              />
            </Box>
          </Paper>
        </Grid>
        <AddLicenseModal
          show={openAddLicenseModal}
          handleClose={handlerLicenseModal}
          user_id={VALUE.currentUser.id}
          getLicenses={() =>
            getLicensesList(
              pagination.offset,
              pagination.size,
              sortActivationDate
            )
          }
        />
      </Box>
    </Layout>
  );
}
