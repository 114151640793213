// @ts-nocheck
import React from "react";
import { TableCell, Typography, IconButton, Box } from "@material-ui/core";
import { timeConverter } from "../../utils/validations";
import CustumMenus from "../../partials/CustumMenus";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import EditContractId from "../../partials/modal/EditContractId";
import { firstLetterUpperCase } from "../../utils/validations";
import EditDays from "../../partials/modal/EditDays";
import EditChannels from "../../partials/modal/EditChannels";

const highlightingLicenses = (value) => {
  const { status, days_left } = value;
  if (status === "expired") {
    return "rgba(255,0,0, 0.7)";
  }
  if (status !== "expired" && days_left <= 5 && days_left !== null) {
    return "#ffa500";
  }
};

export const generateGridData = (result) => {
  const gridColumns = [
    {
      icon: (name, handlerSort) => {
        const defaultValue = "company_name";
        const descValue = "company_name desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Company",
      alignPosition: "center",
      CellRenderer: (value) => {
        const companyName = value.company_name;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{companyName}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Company Contact",
      alignPosition: "center",
      CellRenderer: (value) => {
        const first_name = value.company_owner_first_name;
        const last_name = value.company_owner_last_name;
        const color = highlightingLicenses(value);

        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{first_name}</Typography>
            <Typography>{last_name}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Key",
      alignPosition: "center",
      CellRenderer: (value) => {
        const key = value.id;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{key}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "ID",
      alignPosition: "center",
      CellRenderer: (value) => {
        const licenseID = value.license_id;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{licenseID}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Contract",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const contractId = value.contract_id;
        const isTrial = !value.trial;
        const color = highlightingLicenses(value);

        return (
          <TableCell align="center">
            <Box display="flex" justifyContent="space-evenly">
              <Typography style={{ color: color }}>{contractId}</Typography>
              {isTrial && (
                <EditContractId
                  id={value.id}
                  handler={handler}
                  valueId={value.contract_id}
                />
              )}
            </Box>
          </TableCell>
        );
      },
    },
    {
      name: "Channels",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const cameras = value.cameras_count;
        const notActivated = value.status === "not activated";
        const key = value.id;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Box
              style={
                notActivated
                  ? {
                      display: "flex",
                      justifyContent: "space-evenly",
                      width: "100px",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <Typography>{cameras}</Typography>
              {notActivated && (
                <EditChannels
                  cameras={String(cameras)}
                  id={key}
                  handler={handler}
                />
              )}
            </Box>
          </TableCell>
        );
      },
    },
    {
      name: "Days",
      alignPosition: "center",
      CellRenderer: (value, handler) => {
        const days = value.days;
        const notActivated = value.status === "not activated";
        const key = value.id;
        const color = highlightingLicenses(value);

        return (
          <TableCell align="center" style={{ color: color }}>
            <Box
              style={
                notActivated
                  ? {
                      display: "flex",
                      justifyContent: "space-evenly",
                      width: "100px",
                    }
                  : { display: "flex", justifyContent: "center" }
              }
            >
              <Typography>{days}</Typography>
              {notActivated && (
                <EditDays item={days} handler={handler} id={key} />
              )}
            </Box>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "activated_at";
        const descValue = "activated_at desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Activation Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const activatedAt = value.activated_at;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>
              {activatedAt !== null ? timeConverter(activatedAt) : ""}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "expires_at";
        const descValue = "expires_at desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              color="primary"
              style={{ backgroundColor: "transparent" }}
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Expiration Date",
      alignPosition: "center",
      CellRenderer: (value) => {
        const expiresAt = value.expires_at;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>
              {expiresAt !== null ? timeConverter(expiresAt) : ""}
            </Typography>
          </TableCell>
        );
      },
    },
    {
      icon: (name, handlerSort) => {
        const defaultValue = "days_left";
        const descValue = "days_left desc";
        return (
          <Box display="flex">
            <Typography>{name}</Typography>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              color="primary"
              onClick={() => handlerSort(defaultValue, descValue)}
            >
              <UnfoldMoreIcon />
            </IconButton>
          </Box>
        );
      },
      name: "Days Left",
      alignPosition: "center",
      CellRenderer: (value) => {
        const daysLeft = value.days_left;
        const color = highlightingLicenses(value);
        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{daysLeft}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Account Manager",
      alignPosition: "center",
      CellRenderer: (value, _, role) => {
        const email = value.creator_email;
        const color = highlightingLicenses(value);

        return (
          role === "admin" && (
            <TableCell align="center" style={{ color: color }}>
              <Typography>{email}</Typography>
            </TableCell>
          )
        );
      },
    },
    {
      name: "Type",
      alignPosition: "center",
      CellRenderer: (value) => {
        const type = value.trial;
        const color = highlightingLicenses(value);

        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{type ? "Demo" : "Regular"}</Typography>
          </TableCell>
        );
      },
    },
    {
      name: "Status",
      alignPosition: "center",
      CellRenderer: (value) => {
        const status = firstLetterUpperCase(value.status);
        const color = highlightingLicenses(value);

        return (
          <TableCell align="center" style={{ color: color }}>
            <Typography>{status}</Typography>
          </TableCell>
        );
      },
    },

    {
      name: "Action",
      alignPosition: "center",
      CellRenderer: (
        _,
        rowData,
        handlerEnableChange,
        editDays,
        editChannels,
        extendActivatedExpired,
        addChannelsActivatedExpired
      ) => {
        const isTrialLicense = rowData.trial;
        return (
          <TableCell align="center">
            {!isTrialLicense && (
              <CustumMenus
                element={rowData}
                handlerEnableChange={handlerEnableChange}
                editDays={editDays}
                editChannels={editChannels}
                extendActivatedExpired={extendActivatedExpired}
                addChannelsActivatedExpired={addChannelsActivatedExpired}
                hasHistory
              />
            )}
          </TableCell>
        );
      },
    },
  ];

  const gridRows = result;

  return {
    gridColumns,
    gridRows,
  };
};
